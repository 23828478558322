#polo-session-helper {
    font-family: 'Georgia';
    position: fixed;
    z-index: 99999;
    &[data-pos-x="left"] {
        left: 0;
    }
    &[data-pos-x="right"] {
        right: 0;
    }
    &[data-pos-y="top"] {
        top: 0;
    }
    &[data-pos-y="bottom"] {
        bottom: 0;
    }

    .session-helper__component {
        
    }
}