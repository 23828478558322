.helper-overlay__component {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(255, 255, 255, .5);
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #232323;

    span.__text {
        font-size: xx-large;
    }

    a.__link {
        font-size: larger;
        cursor: pointer;
        text-decoration: underline;
        color: inherit;
    }
}